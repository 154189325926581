import React from "react"
import Link from "gatsby-link"
import "../styles/global.css"
import { graphql } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/SEO"

function pagePath(path) {
    var i = path.indexOf("/");
    var l = path.length;

    return path.substr(i+1, l-(i+4));
  }

export default ({ data }) => (
    <Layout>
        <SEO title="JTF starter site sitemap" description="JTF Sitemap - powered by Gatsby!" />
        <div className="content">
            <h1>Sitemap</h1>
            <p>
                <Link to="/">Home</Link>
            </p>
            {data.allFile.edges.map(({ node }) =>
                <p>
                    <Link to={"../" + pagePath(node.relativePath)}>{node.name}</Link>
                </p>
            )}
        </div>
    </Layout>
)

export const query = graphql`
    query SitemapQuery {
        allFile (filter: { extension: { in: ["js", "md"] }, relativeDirectory: { nin: ["templates", "components", "images", "utils"] }, name: { nin: ["counter", "sitemap", "index"] } }){
           edges {
           node {
            relativePath
            name
           }
        }
    }
}`